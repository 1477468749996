import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import i18n from 'i18next';
import CookieBar from '../Modules/CookieBar/CookieBar';
import {getStaticData} from './utils';
import {deviceDetection} from './deviceUtils';

// import * as Sentry from "@sentry/react";
// import { Integrations } from "@sentry/tracing";

import configureStore from './redux/store';
import loginRefresher from './loginRefresher';
import {productionLog} from './utils';
import {createRoot} from 'react-dom/client';
import ReCaptchaApi from '../components/Ui/ReCaptcha/RecaptchaApi';

// nastavime spravne tridy "toucher"/"desktop" apod. na body/html
deviceDetection();

i18n.init({
	...window['textsConfig'],
	keySeparator: false,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false, // TODO mozna jen tam kde to bude fakt nutny
		prefix: '{',
		suffix: '}',
	},
});

// Sentry.init({
// 	dsn: "https://9fca8924c44f44ed973da864a81c7ddf@o497237.ingest.sentry.io/5573077",
// 	autoSessionTracking: true,
// 	integrations: [
// 		new Integrations.BrowserTracing(),
// 	],
//
// 	// We recommend adjusting this value in production, or using tracesSampler for finer control
// 	tracesSampleRate: 1.0,
// 	denyUrls: [
//     	// Facebook flakiness
//     	/graph\.facebook\.com/i,
//     	// Facebook blocked
//     	/connect\.facebook\.net\/en_US\/all\.js/i,
// 	]
// });


const url = new URL(window.location);
const location = window.location;
productionLog('production Login Active');

/**
 * Pred vykreslenim obali komponentu do Redux store provideru, prida CookieBar pokud je released
 *
 * @description prida do PageComponent propsy:
 *  - `url` URL objekt vytvoreny podle window.location
 *  - `location` samotny window.location
 *  - `staticData` obsahujici vsechny staticke data pokud `mapStaticDataToPageProps` chybi,
 *  jinak data z `mapStaticDataToPageProps`
 *
 * @template {React.Component | React.FunctionComponent} T
 * @param {T} PageComponent
 * @param {function|ReturnType<configureStore>} rootReducer
 * @param element
 * @param {null|((staticData: Record<string, unknown>) => React.ComponentProps<T>)} mapStaticDataToProps
 * @param react17Fallback
 */
export default (PageComponent, rootReducer, element = null, mapStaticDataToProps = null, react17Fallback = false) =>
{
	const staticData = getStaticData();
	if ('reCaptcha' in staticData)
	{
		ReCaptchaApi.init(staticData.reCaptcha);
	}

	let store = rootReducer;
	if (typeof rootReducer === 'function')
	{
		store = configureStore(rootReducer);
	}

	const data = mapStaticDataToProps ? mapStaticDataToProps(staticData) : {staticData};

	const ReactElement = <Provider store={store}>
		<>
			<PageComponent
				{...data}
				url={url}
				location={location}
			/>
			<CookieBar
				cookieDisclosureUrl={getStaticData().cookieDisclosureUrl}
				location={window.location}
			/>
		</>
	</Provider>;
	const container = element || window.document.getElementById('root');

	if (react17Fallback)
	{
		// ReactDOM.hydrate(
		ReactDOM.render(ReactElement, container);
	}
	else
	{
		// const root = hydrateRoot(container, ReactElement);
		const root = createRoot(container);
		root.render(ReactElement);
	}

	// po vykreslení začneme timer
	loginRefresher.start();
};
