import * as Sentry from '@sentry/react';

// https://docs.sentry.io/product/sentry-basics/integrate-frontend/initialize-sentry-sdk/#2-add-the-sentry-react-sdk
Sentry.init({
	dsn: 'https://9fca8924c44f44ed973da864a81c7ddf@o497237.ingest.us.sentry.io/5573077',
	autoSessionTracking: true,
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration({
			networkDetailAllowUrls: [
				window.location.origin,
				/.*/, // all
			],
		}),
	],
	attachStacktrace: true,
	// We recommend adjusting this value in production, or using tracesSampler for finer control
	tracesSampleRate: 0.2, // 20% of traces span
	denyUrls: [
		// Facebook flakiness
		/graph\.facebook\.com/i,
		// Facebook blocked
		/connect\.facebook\.net\/en_US\/all\.js/i,
		// Team.blue tracking
		/motu\.teamblue\.services/i,
		// Google recaptcha
		/www\.google\.com\/recaptcha/i,
		/www\.gstatic\.com\//i,
		// Google tag manager
		/www\.googletagmanager\.com\//i,

		// Chrome extensions JS
		/chrome-extension:\/\//,
	],

	ignoreErrors: [
		/Request aborted/, 
		/Network Error/,
		/NetworkError/,
		/The operation was aborted./,
		/Failed to fetch/,
		/Load failed/, // https://sentry.io/answers/load-failed-javascript/
		/Non-Error promise rejection captured with value: Timeout/,
		/Non-Error promise rejection captured with value: Request timeout/,
	],

	replaysSessionSampleRate: 0.01, // Capture Replay for 1% of all sessions,
	replaysOnErrorSampleRate: 0.1,  // plus for 10% of sessions with an error
});

Sentry.setTag('internal_identifier', wnd.$dataStatic.attributes['internal-identifier'] || '');
